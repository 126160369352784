import { Typography } from "@mui/material";
import React from "react";
import { Card } from "react-bootstrap";
import { KTCard } from "../../_metronic/helpers";
import { PageTitle } from "../../_metronic/layout/core";

const Help: React.FC = () => {
  return (
    <Card className="p-6">
      <PageTitle>Help</PageTitle>
      <>
        <KTCard>
          <>
            <Typography variant="h4" textAlign={"center"}>
              Coming Soon!
            </Typography>
          </>
        </KTCard>
      </>
    </Card>
  );
};

export default Help;
