import { useFormik } from "formik";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import "react-phone-input-2/lib/style.css";
import { useMutation } from "react-query";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import { addEventCategory, editEventCategory } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";

type Props = {
  show: boolean;
  isEdit: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: {
    id?: string | undefined;
    categoryName: string;
  };
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditEventCategory = ({
  show,
  handleClose,
  isEdit,
  editData,
}: Props) => {
  const profileDetailsSchema = Yup.object().shape({
    eventCategoryName: Yup.string().required("Category Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      eventCategoryName: editData?.categoryName ?? "",
    },
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      let bodyParams = {
        id: editData ? editData?.id : 0,
        categoryName: values?.eventCategoryName,
      };
      const response = editData
        ? await editEventCategoryAPI(bodyParams)
        : await addEventCategoryAPI(bodyParams);
      if (response.result) {
        snackActions.success(response?.message ?? "Added/Edited Successfully!");
        formik.resetForm();
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  const {
    mutateAsync: addEventCategoryAPI,
    isLoading: isAdding,
    error: addError,
  } = useMutation("add-admin", addEventCategory);

  const {
    mutateAsync: editEventCategoryAPI,
    isLoading: isEditning,
    error: editError,
  } = useMutation("add-admin", editEventCategory);

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop={true}
    >
      <div className="modal-header">
        <h2>{isEdit ? "Edit" : "Add"} Category</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <label className="form-label fw-bolder text-dark fs-6">
                  Category Name
                </label>
                <input
                  placeholder="Enter category name"
                  type="text"
                  autoComplete="off"
                  {...formik.getFieldProps("eventCategoryName")}
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                />
                {formik.touched.eventCategoryName &&
                  formik.errors.eventCategoryName && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.eventCategoryName}
                      </div>
                    </div>
                  )}
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "15px",
              }}
            >
              <Button
                onClick={() => {
                  formik.resetForm();
                  handleClose(false);
                }}
                size="lg"
                variant="secondary"
              >
                Cancel
              </Button>
              <div className="w-15px"></div>
              <Button type="submit" size="lg" variant="primary">
                {isAdding ? "Loading.." : "Submit"}
              </Button>
            </div>
          </Container>
        </form>
      </div>
    </Modal>,
    modalsRoot,
  );
};

export default AddEditEventCategory;
