import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useMutation } from "react-query";
import { KTCard } from "../../_metronic/helpers";
import ConfirmEventPopup from "../../_metronic/layout/components/common/ConfirmEventPopup";
import CustomTooltip from "../../_metronic/layout/components/common/CustomTooltip";
import { deleteBlog, getAllBlog } from "../../api";
import MuiTable from "../../components/table/MuiTable";
import { charValidate } from "../../components/utility";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import useDebounce from "../../hooks/useDebounce";
import AddEditBlog from "./AddEditBlog";
// import AddEditUser from "./AddEditUser";

const Blog: React.FC = () => {
  const tableRef = useRef(null);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  // const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const [tableOptions, setTableOptions] = useState({
    skip: 0,
    take: 10,
    totalRowCount: undefined,
    roleId: 3,
    SearchText: "",
    isBanned: false,
  });

  const debounceSearchValue: string = useDebounce(
    tableOptions?.SearchText,
    500,
  );
  const {
    mutateAsync: getBlogAPI,
    data: blogData,
    isLoading: isGettingListData,
  } = useMutation(getAllBlog);

  const { mutateAsync: deleteBlogAPI, isLoading: isDeleting } = useMutation(
    "delete-blog",
    deleteBlog,
  );

  useEffect(() => {
    getBlogList({
      ...tableOptions,
      SearchText: debounceSearchValue ?? "",
    });
  }, [debounceSearchValue]);

  const getBlogList = async (
    query:
      | {
          skip: number;
          take: number;
          SearchText: string;
        }
      | undefined,
  ) => {
    let response = await getBlogAPI(query);

    if (response?.result) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          skip: response?.pageDetail?.skip ?? 0,
          take: response?.pageDetail?.take ?? 10,
          SearchText: response?.pageDetail?.searchText ?? "",
          totalRowCount: response?.pageDetail?.count ?? 0,
        };
      });
    }
  };

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      sortable: false,
      minWidth: 200,
      renderCell: (data: any) => (
        <CustomTooltip
          title={
            data?.row ? (
              <h6
                style={{ color: "white" }}
              >{`${data?.row?.title} ${data?.row?.title}`}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>{`${data?.row?.title}`}</span>
        </CustomTooltip>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      sortable: false,
      minWidth: 270,
      renderCell: (data: any) => (
        <CustomTooltip
          title={
            data?.row?.description ? (
              <h6 style={{ color: "white" }}>{data?.row?.description}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.description
              ? charValidate(data?.row?.description, 40)
              : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "redirectUrl",
      headerName: "Redirect Url",
      flex: 1,
      sortable: false,
      renderCell: (data: any) =>
        data?.row?.redirectUrl ? `${data?.row?.redirectUrl}` : "N/A",
    },
    {
      field: "imageUrl",
      headerName: "Image Url",
      flex: 1,
      sortable: false,
      renderCell: (data: any) =>
        data?.row?.imageUrl ? (
          <div style={{ cursor: "pointer" }}>
            <img
              src={`${data?.row?.imageUrl}`}
              alt=""
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
            />
          </div>
        ) : (
          "N/A"
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Edit">
            <DriveFileRenameOutlineIcon
              onClick={() => {
                setSelectedRow(data?.row);
                setShowAddEditModal(true);
                // setIsEdit(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
          </Tooltip>
          <Tooltip title="Delete Blog">
            <DeleteSweepIcon
              onClick={() => {
                setSelectedRow(data?.row);
                setShowDeleteConfirmModal(true);
              }}
              sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
              color="error"
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  let defaultQuery = {
    skip: 0,
    take: 10,
    SearchText: "",
  };

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query: any = {
      skip:
        pageSize === tableOptions?.take ? (page === 0 ? 0 : `${page}` + 0) : 0,
      take: pageSize ?? 10,
      SearchText: "",
    };
    getBlogList(query);
  };

  const handleLastPage: any = () => {
    const totalPages = Math.ceil(
      (tableOptions?.totalRowCount ?? 0) / (tableOptions?.take ?? 10),
    );
    const lastPage = totalPages > 0 ? totalPages - 1 : 0;
    handlePagination({
      page: lastPage,
      pageSize: tableOptions?.take,
    });
  };

  const handleFirstPage: any = () => {
    const firstPage = 0;
    handlePagination({ page: firstPage, pageSize: tableOptions?.take });
  };
  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    if (isSubmit) {
      getBlogList({
        skip: 0,
        take: 10,
        SearchText: "",
      });
    }
  };

  const deleteBlogs = async () => {
    let response = await deleteBlogAPI({ id: selectedRow?.id });
    if (response?.result) {
      snackActions.success(response?.message);
      handleCloseDelete();
      getBlogList(defaultQuery);
    } else {
      snackActions.error(response?.message);
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
  };
  return (
    <Card className="p-6">
      <>
        <KTCard>
          <h3 style={{ position: "absolute", top: "5px" }}>Blog</h3>
          <>
            <MuiTable
              addLabel="Add Blog"
              handleAddClick={() => {
                setShowAddEditModal(true);
                setSelectedRow("");
              }}
              columns={columns}
              tableRef={tableRef}
              isRowClickable={true}
              handlePagination={handlePagination}
              handleLastPage={handleLastPage}
              handleFirstPage={handleFirstPage}
              tableOptions={tableOptions}
              isAddEnable={true}
              data={blogData ? blogData?.result : []}
              loading={isGettingListData}
            />
          </>
        </KTCard>
        {showAddEditModal && (
          <AddEditBlog
            editData={selectedRow}
            show={showAddEditModal}
            handleClose={handleModalClose}
          />
        )}
        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={deleteBlogs}
            loading={isDeleting}
            title={"Delete Blog"}
            message={"Are you sure, want to delete this blog?"}
          />
        )}
      </>
    </Card>
  );
};

export default Blog;
