import { useFormik } from "formik";
import { useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { KTCard } from "../../_metronic/helpers";
import { addInfoWebURL, getAllEventList, getEventInfoChirps } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import CommonDropdown from "./CommonDropdown";

const AddWebsite = ({ title = "" }: { title: string }) => {
  const profileDetailsSchema = Yup.object().shape({
    website: Yup.string().required("Website Name is required"),
    title: Yup.string().required("Title Name is required"),
    eventList: Yup.string().required("This field is required"),
    // infochirps: Yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      website: "",
      title: "",
      eventList: "",
      infochirps: "",
    },
    validationSchema: profileDetailsSchema,
    onSubmit: async (values: any) => {
      let bodyParams = {
        id: 0,
        eventId: values?.eventList,
        infoChirpId: 4,
        title: values.title,
        webUrl: values.website,
      };
      const response = await addWebURL(bodyParams);
      if (response.result) {
        snackActions.success(response?.message ?? "Added Successfully!");
        formik.resetForm();
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  let eventValue = formik.values.eventList;

  let defaultQuery = {
    skip: 0,
    take: 100000,
    id: "",
    SearchText: "",
  };

  const {
    mutateAsync: getInfoChirps,
    data: infoChirpsData,
    isLoading: isGettingInforChirps,
  } = useMutation(getEventInfoChirps);

  const {
    mutateAsync: addWebURL,
    data: webURLData,
    isLoading: isAddingWebURL,
  } = useMutation(addInfoWebURL);

  useEffect(() => {
    getInfoChirps({ eventId: eventValue });
  }, []);

  const {
    mutateAsync: getEventData,
    data: listData,
    isLoading: isGettingListData,
  } = useMutation(getAllEventList);

  useEffect(() => {
    getEventData(defaultQuery);
  }, []);

  return (
    <Card className="p-6">
      {/* <PageTitle>Website</PageTitle> */}
      <>
        <KTCard>
          <>
            <h2 className="mb-8" style={{ color: "#272781" }}>
              {`${title} : `}
            </h2>
            <div>
              <div className="p-2">
                <CommonDropdown formik={formik} />
                <form
                  onSubmit={formik.handleSubmit}
                  noValidate
                  className="form"
                >
                  <Container fluid>
                    <Row className="mt-5">
                      <Col xs={6}>
                        <label className="form-label fw-bold">Title</label>
                        <input
                          placeholder="Enter Title"
                          type="text"
                          autoComplete="off"
                          {...formik.getFieldProps("title")}
                          className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        />
                        {formik.touched.title && formik.errors.title && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.title}
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col xs={6}>
                        <label className="form-label fw-bold">
                          Website Link
                        </label>
                        <input
                          placeholder="Enter Website"
                          type="text"
                          autoComplete="off"
                          {...formik.getFieldProps("website")}
                          className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        />
                        {formik.touched.website && formik.errors.website && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.website}
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row></Row>
                  </Container>
                  <Button
                    type="submit"
                    size="lg"
                    variant="primary"
                    className="d-flex ms-auto mt-6"
                  >
                    {isAddingWebURL ? "Loading.." : "Submit"}
                  </Button>
                </form>
              </div>
            </div>
          </>
        </KTCard>
      </>
    </Card>
  );
};

export default AddWebsite;
