import { APP_URLs, config } from "../constants/appURLs";
import { snackActions } from "../helpers/SnackUtilsConfigurator";
import httpClient from "./httpClient";

const deleteCategoryEvent = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(
      `${APP_URLs.event.deleteCategoryEvents}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const deleteEvents = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(
      `${APP_URLs.event.deleteEvents}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const deleteLocation = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(
      `${APP_URLs.location.deleteLocation}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

// Common
const getCountries = async () => {
  try {
    const { data } = await httpClient.get(
      APP_URLs.commonEndPoints.getCountries,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const getProfileImg = async () => {
  try {
    const { data } = await httpClient.get(
      APP_URLs.commonEndPoints.getProfile,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getEventCategory = async () => {
  try {
    const { data } = await httpClient.get(
      APP_URLs.commonEndPoints.getEventCategory,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const getLocationLists = async () => {
  try {
    const { data } = await httpClient.get(
      APP_URLs.commonEndPoints.getLocation,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getStates = async (payloadData: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.commonEndPoints.getStates}/${payloadData?.countryId}/?Skip=0&Take=10000`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getCities = async (payloadData: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.commonEndPoints.getCities}/${payloadData?.stateId}/?Skip=0&Take=10000`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const uploadPicture = async (payloadData: any) => {
  config.headers["ContentType"] = "multipart/form-data";
  // config.headers["Content-Type"] = "multipart/form-data";
  try {
    const { data } = await httpClient.post(
      APP_URLs.mediaUpload,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getTerms = async () => {
  try {
    const { data } = await httpClient.get(
      APP_URLs.termsAndCondition.getTerms,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getPrivacy = async () => {
  try {
    const { data } = await httpClient.get(
      APP_URLs.privacyAndPolicy.getPrivacy,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getAboutUs = async () => {
  try {
    const { data } = await httpClient.get(APP_URLs.aboutUs.getAboutUs, config);
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const getUsers = async () => {
  try {
    const { data } = await httpClient.get(
      APP_URLs.commonEndPoints.getUsers,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const blockUsers = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      `${APP_URLs.commonEndPoints.blockUsers}/${payloadData?.userId}?isBanned=${payloadData?.isBanned}`,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addAboutUs = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.aboutUs.addAboutUs,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addTerms = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.termsAndCondition.addTerms,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addEvent = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.event.addEvents,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addParticipants = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.event.addParticipants,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const editEvent = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.event.addEvents,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addEventCategory = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.event.addCategoryEvents,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const editEventCategory = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.event.addCategoryEvents,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addPrivacy = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.privacyAndPolicy.addPrivacy,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const addAdmin = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.AdminUser.addAdmin,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const editAdmin = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.AdminUser.editAdmin,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteAdmin = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(
      `${APP_URLs.AdminUser.deleteAdmin}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addInfoTab = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.infotabs.getAllInfoTabs,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const addInfoNote = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.infotabs.addNote,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const addInfoWebURL = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.infotabs.addWebURL,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const addInfoRSVP = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.infotabs.addRsvp,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const addInfoSocial = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.infotabs.addSocial,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const addInfoImage = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.infotabs.addImage,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const addInfoCheckList = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.infotabs.addCheckList,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const addChoiceList = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.infotabs.addChoiceList,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addInfoPoll = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.infotabs.addPoll,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addInfoDoc = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.infotabs.addDocument,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const editInfoTab = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.infotabs.getAllInfoTabs,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteInfo = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(
      `${APP_URLs.infotabs.getAllInfoTabs}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getAppCustomerList = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.appUser.appCustomerList}?roleId=${query?.roleId}&offset=${query?.offset}&limit=${query?.limit}&search=${query?.search}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const sendOTP = async (query: any) => {
  try {
    const { data } = await httpClient.post(
      `${APP_URLs.authEndPoints.sendOTP}?email=${query?.email}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const verifyOTP = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.authEndPoints.verifyOTP,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const resetPassword = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.authEndPoints.resetPassword,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getAllUserList = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.event.getAllUserList}?id=${query?.id}&Skip=${
        query?.skip
      }&Take=${query?.take}&SearchText=${
        query?.search
      }&OrderByColumn=${"CreatedOn"}&OrderByDirection=${"DESC"}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getAllEventList = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.event.getAllEvents}?Skip=${query?.skip}&Take=${query?.take}&SearchText=${query?.SearchText}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const getEventInfoById = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.event.getEventInfoById}/${query?.eventId}?Skip=${query?.Skip}&Take=${query?.take}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getInfoByEventChirpsId = async (query: any) => {
  const updateQuery =
    query?.itemName === "add document"
      ? "document"
      : query?.itemName === "create note"
      ? "note"
      : query?.itemName === "add website link"
      ? "web-links"
      : query?.itemName === "add rsvp"
      ? "event-rsvp"
      : query?.itemName === "add social media"
      ? "social-media-urls"
      : query?.itemName === "add checklist"
      ? "checklist"
      : query?.itemName === "add choicelist"
      ? "choicelist-option"
      : query?.itemName === "add vote or poll"
      ? "poll-option"
      : "";
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.event.getEventByInforChipsId}/${query?.eventId}/${updateQuery}/${query?.eventInfoChirpId}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getParticipantsByEventId = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.event.getParticipantsByEventIds}/${query?.eventId}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getLocationList = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.commonEndPoints.getLocation}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const getMostSearchedLocation = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.commonEndPoints.getMostSearchedLocation}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addLocation = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.location.addLocation,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const editLocation = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.location.addLocation,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getAllEventCategoryList = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.event.getAllEventCategroyList}?id=${query?.id}&Skip=${
        query?.skip
      }&Take=${query?.take}&SearchText=${
        query?.SearchText
      }&OrderByColumn=${"Name"}&OrderByDirection=${"ASC"}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getAllUsersList = async (query: any) => {
  try {
    const params = new URLSearchParams({
      roleId: query?.roleId,
      Skip: query?.skip,
      Take: query?.take,
      SearchText: query?.SearchText,
      OrderByColumn: "firstName",
      OrderByDirection: "ASC",
    });

    if (query?.isBanned !== undefined) {
      params.append("isBanned", query.isBanned);
    }

    const { data } = await httpClient.get(
      `${APP_URLs.genericUserAPI}?${params.toString()}`,
      config,
    );

    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getAllEventContacts = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.event?.getAllEventContacts}?eventId=${query?.eventId}&Skip=${
        query?.skip
      }&Take=${query?.take}&SearchText=${
        query?.SearchText
      }&OrderByColumn=${"Sequence"}&OrderByDirection=${"ASC"}&isBanned=${
        query?.isBanned
      }`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getAllAdminUsersList = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.genericUserAPI}?roleId=${query?.roleId}&Skip=${
        query?.skip
      }&Take=${query?.take}&SearchText=${
        query?.SearchText
      }&OrderByColumn=${"CreatedOn"}&OrderByDirection=${"DESC"}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getAllEventInfoTabsList = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.infotabs.getAllInfoTabs}?Skip=${query?.skip}&&Take=${
        query?.take
      }&SearchText=${
        query?.SearchText
      }&OrderByColumn=${"Sequence"}&OrderByDirection=${"ASC"}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getEventInfoChirps = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.infotabs.getAllInfoTabs}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getDashboardInfo = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.commonEndPoints.getDashboardInfo}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const resetUserPassword = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      `${APP_URLs.authEndPoints.resetUserPassword}/${payloadData?.userId}`,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

//Blog

const getAllBlog = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.blog.genericURL}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addBlog = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.blog.genericURL,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const editBlog = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.blog.genericURL,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteBlog = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(
      `${APP_URLs.blog.genericURL}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

// -----------> Category Bulk Upload <----------

const categoryBulkUpload = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.commonEndPoints.bulkUpload,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

// -----------> App Config Youtube URL  <----------

const getYoutubeUrl = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      APP_URLs.appConfig.getYouTubUrl,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const updateYoutubeUrl = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.appConfig.updateYouTubUrl,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

export {
  addAboutUs,
  addAdmin,
  addBlog,
  addChoiceList,
  addEvent,
  addEventCategory,
  addInfoCheckList,
  addInfoDoc,
  addInfoImage,
  addInfoNote,
  addInfoPoll,
  addInfoRSVP,
  addInfoSocial,
  addInfoTab,
  addInfoWebURL,
  addLocation,
  addParticipants,
  addPrivacy,
  addTerms,
  blockUsers,
  categoryBulkUpload,
  deleteAdmin,
  deleteBlog,
  deleteCategoryEvent,
  deleteEvents,
  deleteInfo,
  deleteLocation,
  editAdmin,
  editBlog,
  editEvent,
  editEventCategory,
  editInfoTab,
  editLocation,
  getAboutUs,
  getAllAdminUsersList,
  getAllBlog,
  getAllEventCategoryList,
  getAllEventContacts,
  getAllEventInfoTabsList,
  getAllEventList,
  getAllUserList,
  getAllUsersList,
  getAppCustomerList,
  getCities,
  getCountries,
  getDashboardInfo,
  getEventCategory,
  getEventInfoById,
  getEventInfoChirps,
  getInfoByEventChirpsId,
  getLocationList,
  getLocationLists,
  getMostSearchedLocation,
  getParticipantsByEventId,
  getPrivacy,
  getProfileImg,
  getStates,
  getTerms,
  getUsers,
  getYoutubeUrl,
  resetPassword,
  resetUserPassword,
  sendOTP,
  updateYoutubeUrl,
  uploadPicture,
  verifyOTP,
};
