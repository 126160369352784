import { Divider } from "@mui/material";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useMutation } from "react-query";
import { getAllEventList } from "../../api";

const CommonDropdown = ({ formik }: { formik: any }) => {
  // let eventValue = formik.values.eventList;

  let defaultQuery = {
    id: "",
    skip: 0,
    take: 100000,
    SearchText: "",
  };

  const {
    mutateAsync: getEventData,
    data: listData,
    isLoading: isGettingListData,
  } = useMutation(getAllEventList);

  useEffect(() => {
    getEventData(defaultQuery);
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={6}>
            <label className="form-label fw-bold">Select Event</label>
            <select
              {...formik.getFieldProps("eventList")}
              className="form-select form-select-solid form-select-lg"
            >
              <option value="">Select Event</option>
              {listData?.result?.map((data: any, index: any) => (
                <option key={index?.id} value={data?.id}>
                  {data?.eventName}
                </option>
              ))}
            </select>
            {formik.touched.eventList && formik.errors.eventList && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.eventList}</div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <Divider sx={{ mt: 5, mb: 1, fontSize: 10 }} />
    </>
  );
};

export default CommonDropdown;
