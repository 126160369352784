import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Typography
} from '@mui/material';
import BootstrapTable from 'react-bootstrap-table-next';

type Props = {
    open: boolean;
    isLoading: boolean;
    onClose: () => void;
    handleExcelFileChange: (e: any) => void;
    uploadSubmit: () => void;
    selectedFile: string;
    fileInputRef: any;
    isSubmitClicked: boolean;
    bulkUploadExcelAPIdata: any;
}

function BulkUploadDialog({ open, onClose, handleExcelFileChange, selectedFile, uploadSubmit, fileInputRef, isLoading, isSubmitClicked, bulkUploadExcelAPIdata }: Props) {
    const columns = [
        {
            dataField: "rowNumber",
            text: "Row Number"
        },
        {
            dataField: "status",
            text: "Status"
        },
        {
            dataField: "message",
            text: "Message"
        },
    ];

    return (
        <Dialog open={open} fullWidth maxWidth="sm">
            <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
                <h2>Bulk upload</h2>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Box sx={{ border: '1px solid grey', p: 1, backgroundColor: "lightblue", mb: 2 }}>
                    <Typography>
                        <strong>Note: </strong>Excel sheet must contain category names under the column <strong>Category Name.</strong>
                    </Typography>
                </Box>
                <div className="border border-dark p-3 w-100">
                    <input type="file" id="file" onChange={handleExcelFileChange} ref={fileInputRef} />
                </div>
            </DialogContent>
            <Divider />
            {isSubmitClicked && (
                <div style={{ padding: 10 }}>
                    <BootstrapTable
                        keyField="rowNumber"
                        data={bulkUploadExcelAPIdata?.result ?? []}
                        columns={columns}
                        striped
                        hover
                        condensed
                    />
                </div>
            )}
            <Divider />
            <DialogActions>
                <Button onClick={onClose} size='large' variant="outlined">
                    Cancel
                </Button>
                <Button variant='contained' color='success' size='large' disabled={isLoading || !selectedFile} onClick={uploadSubmit}>
                    <CloudUploadOutlinedIcon /> &nbsp; {isLoading ? "Loading..." : "Upload"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default BulkUploadDialog;