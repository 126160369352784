/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { getProfileImg } from "../../../../api";
import { APP_URLs } from "../../../../constants/appURLs";
import { useAuth } from "../../../../pages/auth";

const HeaderUserMenu: FC = () => {
  const { currentUser } = useAuth();
  const dispatch = useDispatch();

  const logoutUser = async () => {
    // await dispatch(userLogout());
    localStorage.clear();
    window.location.reload();
  };

  const [profilePopUp, setProfilePopUp] = useState<boolean>(false)

  const { mutateAsync: getUserProfileImg, data: userProfile } =
    useMutation(getProfileImg);

  useEffect(() => {
    getUserProfileImg();
  }, []);

  const userInfoJSON: any = localStorage.getItem("userInfo");
  const userInfo: any = JSON.parse(userInfoJSON);
  const userProfileImg = userProfile?.result?.profileUrl;
  const userImage = `${APP_URLs?.mediaBaseURL}${userProfileImg}`;
  const firstLetterOfLastName = userInfo?.lastName?.charAt(0);
  const firstLetterOfFirstName = userInfo?.firstName?.charAt(0);

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <div
              className={clsx("cursor-pointer symbol", "")}
              data-kt-menu-trigger="{default: 'click'}"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
            >
              {userProfileImg ? (
                <img
                  src={`${userImage}`}
                  style={{
                    height: "50px",
                    width: "50px",
                    objectFit: "cover",
                    borderRadius: "10%",
                  }}
                />
              ) : (
                <div
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "50%",
                    backgroundColor: "#ccc",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textTransform: "uppercase",
                  }}
                >
                  {`${firstLetterOfFirstName}${firstLetterOfLastName}`}
                </div>
              )}
            </div>
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {userInfo?.firstName} {userInfo?.lastName}
              {/* <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">
                Pro
              </span> */}
            </div>
            <span className="fw-bold text-muted text-hover-primary fs-7">
              {userInfo?.email}
            </span>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5" onClick={() => setProfilePopUp(true)}>
        <span className="menu-link px-5">
          My Profile
        </span>
      </div>

      {/* <div className="menu-item px-5">
        <a href="#" className="menu-link px-5">
          <span className="menu-text">My Projects</span>
          <span className="menu-badge">
            <span className="badge badge-light-danger badge-circle fw-bolder fs-7">
              3
            </span>
          </span>
        </a>
      </div> */}

      {/* <div
        className="menu-item px-5"
        data-kt-menu-trigger="hover"
        data-kt-menu-placement="left-start"
        data-kt-menu-flip="bottom"
      >
        <a href="#" className="menu-link px-5">
          <span className="menu-title">My Subscription</span>
          <span className="menu-arrow"></span>
        </a>

        <div className="menu-sub menu-sub-dropdown w-175px py-4">
          <div className="menu-item px-3">
            <a href="#" className="menu-link px-5">
              Referrals
            </a>
          </div>

          <div className="menu-item px-3">
            <a href="#" className="menu-link px-5">
              Billing
            </a>
          </div>

          <div className="menu-item px-3">
            <a href="#" className="menu-link px-5">
              Payments
            </a>
          </div>

          <div className="menu-item px-3">
            <a href="#" className="menu-link d-flex flex-stack px-5">
              Statements
              <i
                className="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="tooltip"
                title="View your statements"
              ></i>
            </a>
          </div>

          <div className="separator my-2"></div>

          <div className="menu-item px-3">
            <div className="menu-content px-3">
              <label className="form-check form-switch form-check-custom form-check-solid">
                <input
                  className="form-check-input w-30px h-20px"
                  type="checkbox"
                  value="1"
                  defaultChecked={true}
                  name="notifications"
                />
                <span className="form-check-label text-muted fs-7">
                  Notifications
                </span>
              </label>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="menu-item px-5">
        <a href="#" className="menu-link px-5">
          My Statements
        </a>
      </div> */}

      <div className="separator my-2"></div>

      {/* <Languages /> */}

      {/* <div className="menu-item px-5 my-1">
        <Link to="/account/setting" className="menu-link px-5">
          Account Settings
        </Link>
      </div> */}

      <div className="menu-item px-5">
        <a onClick={logoutUser} className="menu-link px-5">
          Sign Out
        </a>
      </div>

      <Dialog
        open={profilePopUp}
        onClose={() => setProfilePopUp(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <h2>User Information</h2>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div className="fv-row mb-8">
            <label className="form-label fw-bolder text-dark fs-6">
              First Name
            </label>
            <input
              placeholder="First name"
              type="text"
              autoComplete="off"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              value={userInfo?.firstName}
              disabled
            />
          </div>
          <div className="fv-row mb-8">
            <label className="form-label fw-bolder text-dark fs-6">
              Last Name
            </label>
            <input
              placeholder="Last name"
              type="text"
              autoComplete="off"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              value={userInfo?.lastName}
              disabled
            />
          </div>
          <div className="fv-row mb-8">
            <label className="form-label fw-bolder text-dark fs-6">
              Email
            </label>
            <input
              placeholder="Email"
              type="email"
              autoComplete="off"
              value={userInfo?.email}
              disabled
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            />
          </div>
          <div className="fv-row mb-8">
            <label className="form-label fw-bold">Mobile Number</label>
            <PhoneInput
              country={"us"}
              // value={formik.values.phoneNumber}
              inputStyle={{
                background: "#f9f9f9",
                border: "none",
              }}
              inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0 w-100"
              value={userInfo?.phoneNumber}
              disabled
            />
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={() => setProfilePopUp(false)}
            size="lg"
            variant="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export { HeaderUserMenu };

