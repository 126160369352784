import { useFormik } from "formik";
import { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { KTCard } from "../../_metronic/helpers";
import { addInfoImage, uploadPicture } from "../../api";
import { charValidate } from "../../components/utility";
import { APP_URLs } from "../../constants/appURLs";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import CommonDropdown from "./CommonDropdown";

const AddImg = ({ title = "" }: { title: string }) => {
  const [imgFileName, setImgFileName] = useState<string>();
  const [eventImg, setEventImg] = useState<string>("");

  const profileDetailsSchema = Yup.object().shape({
    eventList: Yup.string().required("This field is required"),
    // infochirps: Yup.string().required("This field is required"),
  });

  const formik: any = useFormik({
    initialValues: {
      eventList: "",
      infochirps: "",
    },
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      let bodyParams = {
        id: 0,
        eventId: values.eventList,
        infoChirpId: 3,
        imagePath: [eventImg],
      };
      const response = await addImage(bodyParams);
      if (response.result) {
        snackActions.success(response?.message ?? "Added Successfully!");
        formik.resetForm();
        setEventImg("");
        setImgFileName("");
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  const { mutateAsync: addImage, isLoading: isAdding } = useMutation(
    "add-image",
    addInfoImage,
  );

  const { mutateAsync: pictureUpload } = useMutation(
    "upload-image",
    uploadPicture,
  );

  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setImgFileName(file?.name ?? "");
    formData.append("files", file);
    formData.append("fileDriveName", "event");
    if (file) {
      let response = await pictureUpload(formData);
      if (response[0]?.isSuccess) {
        setEventImg(`${APP_URLs.mediaBaseURL}/${response[0].fileUrl}`);
      } else {
        snackActions.error(
          response?.message ?? "Issue in image upload, try again latter!",
        );
      }
    }
  };
  return (
    <Card className="p-6">
      {/* <PageTitle>Add Image</PageTitle> */}
      <>
        <KTCard>
          <h2 className="mb-8" style={{ color: "#272781" }}>
            {`${title} : `}
          </h2>
          <CommonDropdown formik={formik} />
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            <label className="form-label fw-bold mt-5">Image upload</label>
            <div>
              <label
                htmlFor="event-img"
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
              >
                {imgFileName ? charValidate(imgFileName, 25) : "Choose File"}
              </label>
              <input
                id="event-img"
                type="file"
                accept="image/*"
                className="form-control form-control-lg form-control-solid mb-lg-0"
                placeholder="Upload Image"
                aria-label="Upload Image"
                onChange={handleChangeImageUpload}
                style={{
                  visibility: "hidden",
                }}
              />
            </div>
            <div>
              {eventImg && (
                <div>
                  <img
                    src={eventImg}
                    alt="Event Image"
                    style={{
                      width: "100px",
                      height: "100px",
                      marginLeft: "38%",
                      borderRadius: "20%",
                    }}
                  />
                  <span
                    onClick={() => {
                      setEventImg("");
                      setImgFileName("");
                    }}
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      bottom: "50px",
                      right: "7px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="red"
                      className="bi bi-x-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                    </svg>
                  </span>
                </div>
              )}
              <Button
                type="submit"
                size="lg"
                variant="primary"
                className="d-flex ms-auto"
              >
                {isAdding ? "Loading.." : "Submit"}
              </Button>
            </div>
          </form>
        </KTCard>
      </>
    </Card>
  );
};

export default AddImg;
