import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useMutation } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { verifyOTP } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import OTPInput from "./OTPInput";

export function OTPVerify() {
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [otp, setOTP] = useState<string[]>(Array(6).fill(""));
  const navigate = useNavigate();
  const location: any = useLocation();
  let otpValues = otp?.join("") || [""];

  const { mutateAsync: verifyOTPForgotPassword, isLoading: isLoading } =
    useMutation(verifyOTP);

  useEffect(() => {
    if (otpValues?.length === 6) {
      setHasErrors(false);
    }
  }, [otpValues]);

  const handleSubmit = async () => {
    if (!otpValues || otpValues?.length !== 6) {
      setHasErrors(true);
      return;
    }
    let bodyParam = {
      otp: otpValues ?? "",
      email: location?.state?.email ?? "",
    };

    let response = await verifyOTPForgotPassword(bodyParam);
    if (response?.result) {
      snackActions.success(response?.message ?? "Success");
      navigate("/auth/reset-password", {
        state: { email: location?.state?.email ?? "" },
      });
    } else {
      snackActions.error(response?.message ?? "Something went wrong!");
    }
  };

  return (
    <>
      <div className="text-center mb-10">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3">OTP Verification</h1>
        {/* end::Title */}
      </div>

      {/* begin::Form group */}
      <div className="fv-row mb-8">
        <Container className="mt-5">
          <OTPInput length={6} otp={otp} setOTP={setOTP} />
        </Container>
        {hasErrors && (
          <div className="fv-plugins-message-container pl-3 pt-1">
            <div className="fv-help-block">
              <span role="alert">
                OTP number is required and should be 6 digit
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="d-flex flex-wrap pb-lg-0">
        <button
          id="kt_password_reset_submit"
          className="btn btn-primary me-4"
          onClick={handleSubmit}
        >
          <span className="indicator-label">
            {isLoading ? "Loading..." : "Submit"}
          </span>
        </button>
        <Link to="/auth/forgot-password">
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-light"
          >
            Back
          </button>
        </Link>{" "}
      </div>
      {/* end::Form group */}
    </>
  );
}
