import { useFormik } from "formik";
import { useState } from "react";
import { Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { KTCard } from "../../_metronic/helpers";
import { addInfoDoc, uploadPicture } from "../../api";
import { charValidate } from "../../components/utility";
import { APP_URLs } from "../../constants/appURLs";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import CommonDropdown from "./CommonDropdown";

const AddDocument = ({ title = "" }: { title: string }) => {
  const [imgFileName, setImgFileName] = useState<string>();
  const [eventImg, setEventImg] = useState<string>("");

  const profileDetailsSchema = Yup.object().shape({
    eventList: Yup.string().required("This field is required"),
    // infochirps: Yup.string().required("This field is required"),
  });

  const formik: any = useFormik({
    initialValues: {
      eventList: "",
      infochirps: "",
    },
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      let bodyParams = {
        id: 0,
        eventId: values.eventList,
        infoChirpId: 2,
        documentModel: [
          {
            fileName: "infochirpsDocuments",
            path: eventImg,
          },
        ],
      };
      const response = await addDocument(bodyParams);
      if (response.result) {
        snackActions.success(response?.message ?? "Added Successfully!");
        formik.resetForm();
        setEventImg("");
        setImgFileName("");
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  const { mutateAsync: addDocument, isLoading: isAdding } = useMutation(
    "add-document",
    addInfoDoc,
  );

  const { mutateAsync: pictureUpload } = useMutation(
    "upload-image",
    uploadPicture,
  );

  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setImgFileName(file?.name ?? "");
    formData.append("files", file);
    formData.append("fileDriveName", "event");
    if (file) {
      let response = await pictureUpload(formData);
      if (response[0]?.isSuccess) {
        setEventImg(`${APP_URLs.mediaBaseURL}/${response[0].fileUrl}`);
      } else {
        snackActions.error(
          response?.message ?? "Issue in image upload, try again latter!",
        );
      }
    }
  };
  return (
    <Card className="p-6">
      {/* <PageTitle>Document</PageTitle> */}
      <>
        <KTCard>
          <h2 className="mb-8" style={{ color: "#272781" }}>
            {`${title} : `}
          </h2>
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            <CommonDropdown formik={formik} />
            <label className="form-label fw-bold mt-5 ml-6">
              Document upload
            </label>
            <div className="w-50">
              <label
                htmlFor="event-document"
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
              >
                {imgFileName ? charValidate(imgFileName, 25) : "Choose File"}
              </label>
              <input
                id="event-document"
                type="file"
                accept="application/pdf"
                className="form-control form-control-lg form-control-solid mb-lg-0"
                placeholder="Upload Document"
                aria-label="Upload Document"
                onChange={handleChangeImageUpload}
                style={{
                  visibility: "hidden",
                }}
              />
            </div>
            <div>
              {eventImg && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      width: "80px",
                      height: "80px",
                      background: "#eaf2fe",
                      borderRadius: "8%",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>{eventImg}</Tooltip>}
                    >
                      <i
                        className="bi bi-file-pdf"
                        style={{ fontSize: "3rem", marginTop: "10px" }}
                      ></i>
                    </OverlayTrigger>
                    <p>{charValidate(eventImg, 10)}</p>
                    <span
                      onClick={() => {
                        setEventImg("");
                        setImgFileName("");
                      }}
                      style={{
                        position: "relative",
                        cursor: "pointer",
                        bottom: "105px",
                        left: "40px",
                        fontSize: "2rem",
                      }}
                    >
                      <i
                        className="bi bi-x-circle-fill"
                        style={{ color: "red" }}
                      ></i>
                    </span>
                  </div>
                </div>
              )}
              <Button
                type="submit"
                size="lg"
                variant="primary"
                className="d-flex ms-auto"
              >
                {isAdding ? "Loading.." : "Submit"}
              </Button>
            </div>
          </form>
        </KTCard>
      </>
    </Card>
  );
};

export default AddDocument;
