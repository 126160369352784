import { useFormik } from "formik";
import { useState } from "react";
import { Button, Card, Container, Row } from "react-bootstrap";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { KTCard } from "../../_metronic/helpers";
import { addInfoSocial } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import CommonDropdown from "./CommonDropdown";

const AddSocialMedia = ({ title = "" }: { title: string }) => {
  const profileDetailsSchema = Yup.object().shape({
    medialink: Yup.string().required("This field is required"),
    title: Yup.string().required("Title Name is required"),
    eventList: Yup.string().required("This field is required"),
    // infochirps: Yup.string().required("This field is required"),
  });

  const formik: any = useFormik({
    initialValues: {
      inputs: [
        {
          medialink: "",
          title: "",
        },
      ],
      eventList: "",
      infochirps: "",
    },
    validationSchema: "",
    onSubmit: async (values) => {
      let bodyParams = {
        id: 0,
        eventId: values?.eventList,
        infoChirpId: 13,
        socialMedia: values?.inputs?.map((input) => ({
          title: input.title,
          socialMediaUrl: input.medialink,
        })),
      };
      const response = await addSocial(bodyParams);
      if (response.result) {
        snackActions.success(response?.message ?? "Added Successfully!");
        formik.resetForm();
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });
  const {
    mutateAsync: addSocial,
    data: socialData,
    isLoading: isAddingSocial,
  } = useMutation(addInfoSocial);

  const [inputs, setInputs] = useState([{ title: "", medialink: "" }]);

  // const handleInputChange = (index: any, event: any) => {
  //   const { name, value } = event.target;
  //   const newInputs = [...inputs];
  //   newInputs[index][name as keyof Input] = value;
  //   setInputs(newInputs);
  // };

  const handleAddFields = () => {
    setInputs([...inputs, { title: "", medialink: "" }]);
  };

  const handleRemoveFields = (index: any) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  return (
    <Card className="p-2">
      {/* <PageTitle>Social Media</PageTitle> */}
      <>
        <KTCard>
          <>
            <h2 className="mb-8" style={{ color: "#272781" }}>
              {`${title} : `}
            </h2>
            <form onSubmit={formik.handleSubmit} noValidate className="form">
              <CommonDropdown formik={formik} />
              <Container fluid>
                <Row>
                  <span
                    onClick={handleAddFields}
                    style={{
                      width: "auto",
                      margin: "5px 10px 0 auto",
                      cursor: "pointer",
                      color: "blue",
                    }}
                  >
                    {/* <i className="bi bi-file-plus-fill fs-4"></i> */}
                    <i className="bi bi-plus-circle-fill fs-4"></i>
                  </span>
                  {inputs.map((input, index: any) => (
                    <div key={index} className="d-flex">
                      <div className="flex-grow-1 me-3">
                        <label className="form-label fw-bold">Title</label>
                        <input
                          placeholder="Enter Title"
                          type="text"
                          autoComplete="off"
                          {...formik.getFieldProps(`inputs[${index}].title`)}
                          className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        />
                        {formik.touched.title && formik.errors.title && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.title}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="flex-grow-1">
                        <label className="form-label fw-bold">
                          Social Media Link
                        </label>
                        <div>
                          <input
                            type="text"
                            autoComplete="off"
                            {...formik.getFieldProps(
                              `inputs[${index}].medialink`,
                            )}
                            placeholder="Enter social link"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                          />
                          {formik.touched.medialink && formik.errors.medialink && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.medialink}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {index > 0 && (
                        <span onClick={() => handleRemoveFields(index)}>
                          <i className="bi bi-trash-fill text-danger cursor-pointer"></i>
                        </span>
                      )}
                    </div>
                  ))}
                </Row>
              </Container>
              <Button
                type="submit"
                size="lg"
                variant="primary"
                className="d-flex ms-auto mt-6"
              >
                Submit
              </Button>
            </form>
          </>
        </KTCard>
      </>
    </Card>
  );
};

export default AddSocialMedia;
