import { useFormik } from "formik";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import "react-phone-input-2/lib/style.css";
import { useMutation } from "react-query";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import { addInfoTab, editInfoTab } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  isEdit: boolean;
  editData: {
    id?: string | undefined;
    name: string;
    displayName: string;
  };
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditInfoTab = ({ show, handleClose, isEdit, editData }: Props) => {
  const profileDetailsSchema = Yup.object().shape({
    displayName: Yup.string().required("Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: editData?.name ?? "",
      displayName: editData?.displayName ?? "",
    },
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      let bodyParams = {
        id: editData ? editData?.id : 0,
        name: values.name,
        displayName: values.displayName,
      };
      const response = editData
        ? await editInfoTabData(bodyParams)
        : await addInfoTabs(bodyParams);
      if (response.result) {
        snackActions.success(response?.message ?? "Added/Edited Successfully!");
        formik.resetForm();
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  const {
    mutateAsync: addInfoTabs,
    isLoading: isAdding,
    error: addError,
  } = useMutation("add-info", addInfoTab);

  const {
    mutateAsync: editInfoTabData,
    isLoading: isEditning,
    error: editError,
  } = useMutation("edit-infochirps", editInfoTab);

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop={true}
    >
      <div className="modal-header">
        {<h2>{isEdit ? "Edit" : "Add"} Info Chirp</h2>}
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <label className="form-label fw-bolder text-dark fs-6 mt-5">
                  Info Chirp Name
                </label>
                <input
                  placeholder="Enter info chirp name"
                  type="text"
                  autoComplete="off"
                  {...formik.getFieldProps("displayName")}
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                />
                {formik.touched.displayName && formik.errors.displayName && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.displayName}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "15px",
              }}
            >
              <Button
                onClick={() => {
                  formik.resetForm();
                  handleClose(false);
                }}
                style={{ marginRight: "10px" }}
                size="lg"
                variant="secondary"
              >
                Cancel
              </Button>
              <Button type="submit" size="lg" variant="primary">
                {isAdding ? "Loading.." : "Submit"}
              </Button>
            </div>
          </Container>
        </form>
      </div>
    </Modal>,
    modalsRoot,
  );
};

export default AddEditInfoTab;
