import { useFormik } from "formik";
import { useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { KTCard } from "../../_metronic/helpers";
import { addInfoPoll, uploadPicture } from "../../api";
import { charValidate } from "../../components/utility";
import { APP_URLs } from "../../constants/appURLs";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import CommonDropdown from "./CommonDropdown";

const AddVotePoll = ({ title = "" }: { title: string }) => {
  const [imgFileName, setImgFileName] = useState<string>();
  const [eventImg, setEventImg] = useState<string>("");
  const profileDetailsSchema = Yup.object().shape({
    medialink: Yup.string().required("This field is required"),
    title: Yup.string().required("Title Name is required"),
    eventList: Yup.string().required("This field is required"),
    // infochirps: Yup.string().required("This field is required"),
  });

  const formik: any = useFormik({
    initialValues: {
      inputs: [
        {
          optionImage: "",
          optionName: "",
        },
      ],
      eventList: "",
      infochirps: "",
      title: "",
      optionName: "",
      date: "",
    },
    validationSchema: "",
    onSubmit: async (values) => {
      let bodyParams = {
        id: 0,
        eventId: values?.eventList,
        infoChirpId: 9,
        title: values?.title,
        expireOn: values?.date,
        options: values?.inputs?.map((input) => ({
          optionName: input.optionName,
          image: "",
        })),
      };
      const response = await addPoll(bodyParams);
      if (response.result) {
        snackActions.success(response?.message ?? "Added Successfully!");
        formik.resetForm();
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });
  const {
    mutateAsync: addPoll,
    data: pollData,
    // isLoading: isAddingSocial,
  } = useMutation(addInfoPoll);

  const [inputs, setInputs] = useState([{ optionName: "", image: "" }]);

  // const handleAddFields = () => {
  //   setInputs([...inputs, { optionName: "", image: "" }]);
  // };

  // const handleRemoveFields = (index: any) => {
  //   const newInputs = [...inputs];
  //   newInputs.splice(index, 1);
  //   setInputs(newInputs);
  // };

  const { mutateAsync: pictureUpload } = useMutation(
    "upload-image",
    uploadPicture,
  );

  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setImgFileName(file?.name ?? "");
    formData.append("files", file);
    formData.append("fileDriveName", "event");
    if (file) {
      let response = await pictureUpload(formData);
      if (response[0]?.isSuccess) {
        setEventImg(`${APP_URLs.mediaBaseURL}/${response[0].fileUrl}`);
      } else {
        snackActions.error(
          response?.message ?? "Issue in image upload, try again latter!",
        );
      }
    }
  };

  return (
    <Card className="p-2">
      <>
        <KTCard>
          <>
            <h2 className="mb-8" style={{ color: "#272781" }}>
              {`${title} : `}
            </h2>
            <form onSubmit={formik.handleSubmit} noValidate className="form">
              <CommonDropdown formik={formik} />
              <Container fluid>
                <Row>
                  <Col xs={6}>
                    <label className="form-label fw-bold">Title</label>
                    <input
                      placeholder="Enter Name"
                      type="text"
                      autoComplete="off"
                      {...formik.getFieldProps(`title`)}
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.title}
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col xs={6}>
                    <label className="form-label fw-bold">
                      Select Expire Date
                    </label>
                    <input
                      placeholder="Enter Description"
                      type="date"
                      autoComplete="off"
                      {...formik.getFieldProps("date")}
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    />
                    {formik.touched.date && formik.errors.date && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.date}
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  {/* <span
                    onClick={handleAddFields}
                    style={{
                      width: "auto",
                      margin: "15px 10px 0 auto",
                      cursor: "pointer",
                      color: "blue",
                    }}
                  >
                    <i className="bi bi-plus-circle-fill fs-4"></i>
                  </span> */}
                  {inputs.map((input, index: any) => (
                    <div key={index} className="d-flex mt-4">
                      <Col xs={6}>
                        <div className="flex-grow-1 me-3">
                          <label className="form-label fw-bold">Name</label>
                          <input
                            placeholder="Enter Name"
                            type="text"
                            autoComplete="off"
                            {...formik.getFieldProps(
                              `inputs[${index}].optionName`,
                            )}
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                          />
                          {formik.touched.optionName &&
                            formik.errors.optionName && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  {formik.errors.optionName}
                                </div>
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <label className="form-label fw-bold">
                          Image upload
                        </label>
                        <div>
                          <label
                            htmlFor="event-img"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
                          >
                            {imgFileName
                              ? charValidate(imgFileName, 25)
                              : "Choose File"}
                          </label>
                          <input
                            id="event-img"
                            type="file"
                            accept="image/*"
                            className="form-control form-control-lg form-control-solid mb-lg-0"
                            placeholder="Upload Image"
                            aria-label="Upload Image"
                            onChange={handleChangeImageUpload}
                            style={{
                              visibility: "hidden",
                            }}
                          />
                        </div>
                      </Col>
                      {/* {index > 0 && (
                        <span onClick={() => handleRemoveFields(index)}>
                          <i className="bi bi-trash-fill text-danger cursor-pointer"></i>
                        </span>
                      )} */}
                    </div>
                  ))}
                </Row>
              </Container>
              <Button
                type="submit"
                size="lg"
                variant="primary"
                className="d-flex ms-auto mt-6"
              >
                Submit
              </Button>
            </form>
          </>
        </KTCard>
      </>
    </Card>
  );
};

export default AddVotePoll;
