/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import { ContactUs } from "../../components/auth/ContactUs";
import { ForgotPassword } from "../../components/auth/ForgotPassword";
import { Login } from "../../components/auth/Login";
import { OTPVerify } from "../../components/auth/OTPVerify";
import { Registration } from "../../components/auth/Registration";
import { ResetPassword } from "../../components/auth/ResetPassword";

const AuthLayout = () => {
  const location = useLocation();
  useEffect(() => {
    document.body.classList.add("bg-body");
    return () => {
      document.body.classList.remove("bg-body");
    };
  }, []);

  return (
    <div style={{ height: "100%" }} className="d-flex align-items-center">
      <div className="d-grid h-100 w-50">
        <div className="position-absolute" style={{ left: "19%", top: "10%" }}>
          <img
            className="w-200px"
            // style={{ height: "100vh" }}
            src={toAbsoluteUrl("/media/app-logo/logo-light.png")}
          />
        </div>
        <img
          className="w-100 m-auto"
          style={{ height: "100vh" }}
          src={toAbsoluteUrl("/media/bg/image_1.png")}
        />
      </div>
      <div className="pt-10 w-50 d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
        {/* begin::Content */}
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          {/* begin::Logo */}
          {/* <a href="#" className="mb-12">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/default.svg")}
            className="h-45px"
          />
        </a> */}
          {/* end::Logo */}
          {/* begin::Wrapper */}
          <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
        {/* begin::Footer */}
        {location.pathname === "/auth/login" && (
          <div className="d-flex justify-content-center align-items-center p-10">
            <div>
              <p className="p-0 m-0">
                Copyright © 2023 Invitem All rights reserved.
              </p>
            </div>
            <div className="mx-10"></div>
          </div>
        )}
        {/* end::Footer */}
      </div>
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="contactus" element={<ContactUs />} />
      <Route index element={<Login />} />
      <Route path="send-otp" element={<OTPVerify />} />
      <Route path="reset-password" element={<ResetPassword />} />
    </Route>
  </Routes>
);

export { AuthPage };
