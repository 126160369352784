import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useMutation } from "react-query";
import { KTCard } from "../../_metronic/helpers";
import { ChartsWidget2 } from "../../_metronic/partials/widgets";
import { ChartsWidget } from "../../_metronic/partials/widgets/charts/ChartsWidget";
import { getDashboardInfo } from "../../api";

const Dashboard: React.FC = () => {
  const {
    mutateAsync: getDashboardData,
    data: dashboardData,
    isLoading: isGettingListData,
  } = useMutation(getDashboardInfo);
  
  const mostSearchedLocation = dashboardData?.result?.topSearchLocation;
  const eventCategoryWiseLabel =
    dashboardData?.result?.eventCategoryWise?.data?.labels;
  const eventCategoryWiseData =
    dashboardData?.result?.eventCategoryWise?.data?.datasets[0]?.data;

  const eventLocationWiseLabel =
    dashboardData?.result?.eventLocationWise?.data?.labels;
  const eventLocationWiseData =
    dashboardData?.result?.eventLocationWise?.data?.datasets[0]?.data;

  //dashboardData.result.eventCategoryWise.data.labels;
  //dashboardData.result.eventCategoryWise.data.datasets[0].data;

  //dashboardData.result.eventLocationWise.data.labels;
  //dashboardData.result.eventLocationWise.data.datasets[0].data;

  //dashboardData.result.totalEventCount;

  useEffect(() => {
    getDashboardData({});
  }, []);

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <h3 style={{ position: "absolute", top: "10px", left: "10px" }}>
            Dashboard
          </h3>
          <>
            <div className="row mt-20">
              <div className="col-xl-3">
                <div
                  className="card card-custom bg-light-primary card-stretch gutter-b"
                  style={{
                    backgroundPosition: "right top",
                    backgroundSize: "30% auto",
                    backgroundImage:
                      "url(assets/media/svg/shapes/abstract-1.svg)",
                  }}
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-2x svg-icon-danger">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                            fill="#053B50"
                            fillRule="nonzero"
                            opacity="0.3"
                          />
                          <path
                            d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                            fill="#053B50"
                            fillRule="nonzero"
                          />
                        </g>
                      </svg>
                    </span>
                    <span
                      className="card-title font-weight-bolder mb-0 mt-6 d-block"
                      style={{
                        fontWeight: "bold",
                        color: "#555",
                        fontSize: "1.5rem",
                      }}
                    >
                      {dashboardData?.result?.totalUserCount}
                    </span>
                    <span
                      className="font-weight-bold text-muted font-size-sm"
                      style={{ fontWeight: "500 !important" }}
                    >
                      All User
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div
                  className="card card-custom bg-light-primary card-stretch gutter-b"
                  style={{
                    backgroundPosition: "right top",
                    backgroundSize: "30% auto",
                    backgroundImage:
                      "url(assets/media/svg/shapes/abstract-1.svg)",
                  }}
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-2x">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                            fill="green"
                            fillRule="nonzero"
                            opacity="0.3"
                          />
                          <path
                            d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                            fill="green"
                            fillRule="nonzero"
                          />
                        </g>
                      </svg>
                    </span>
                    <span
                      className="card-title font-size-h2 mb-0 mt-6 d-block"
                      style={{
                        fontWeight: "bold",
                        color: "#555",
                        fontSize: "1.5rem",
                      }}
                    >
                      {dashboardData?.result?.activeUser}
                    </span>
                    <span
                      className="font-weight-bold text-muted font-size-sm"
                      style={{ fontWeight: "500 !important" }}
                    >
                      Active Users
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div
                  className="card card-custom bg-light-primary card-stretch gutter-b"
                  style={{
                    backgroundPosition: "right top",
                    backgroundSize: "30% auto",
                    backgroundImage:
                      "url(assets/media/svg/shapes/abstract-1.svg)",
                  }}
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-2x svg-icon-danger">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                            fill="red"
                            fillRule="nonzero"
                            opacity="0.3"
                          />
                          <path
                            d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                            fill="red"
                            fillRule="nonzero"
                          />
                        </g>
                      </svg>
                    </span>
                    <span
                      className="card-title font-weight-bolder mb-0 mt-6 d-block"
                      style={{
                        fontWeight: "bold",
                        color: "#555",
                        fontSize: "1.5rem",
                      }}
                    >
                      {dashboardData?.result?.inactiveUserCount}
                    </span>
                    <span
                      className="font-weight-bold text-muted font-size-sm"
                      style={{ fontWeight: "500 !important" }}
                    >
                      Inactive User
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div
                  className="card card-custom bg-light-primary card-stretch gutter-b"
                  style={{
                    backgroundPosition: "right top",
                    backgroundSize: "30% auto",
                    backgroundImage:
                      "url(assets/media/svg/shapes/abstract-1.svg)",
                  }}
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-2x svg-icon-danger">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"
                            fill="#F78E25"
                            fillRule="nonzero"
                            opacity="0.3"
                          />
                          <path
                            d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                            fill="#F78E25"
                            fillRule="nonzero"
                          />
                        </g>
                      </svg>
                    </span>
                    <span
                      className="card-title font-weight-bolder mb-0 mt-6 d-block"
                      style={{
                        fontWeight: "bold",
                        color: "#555",
                        fontSize: "1.5rem",
                      }}
                    >
                      {mostSearchedLocation}
                    </span>
                    <span
                      className="font-weight-bold text-muted font-size-sm"
                      style={{ fontWeight: "500 !important" }}
                    >
                      Most Visited Location
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-10">
              <div className="col-xl-3">
                <div
                  className="card card-custom bg-light-primary card-stretch gutter-b"
                  style={{
                    backgroundPosition: "right top",
                    backgroundSize: "30% auto",
                    backgroundImage:
                      "url(assets/media/svg/shapes/abstract-1.svg)",
                  }}
                >
                  <div className="card-body">
                    <span className="svg-icon svg-icon-2x svg-icon-danger">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                            fill="#053B50"
                            fillRule="nonzero"
                            opacity="0.3"
                          />
                          <path
                            d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                            fill="#053B50"
                            fillRule="nonzero"
                          />
                        </g>
                      </svg>
                    </span>
                    <span
                      className="card-title font-weight-bolder mb-0 mt-6 d-block"
                      style={{
                        fontWeight: "bold",
                        color: "#555",
                        fontSize: "1.5rem",
                      }}
                    >
                      {dashboardData?.result?.totalEventCount}
                    </span>
                    <span
                      className="font-weight-bold text-muted font-size-sm"
                      style={{ fontWeight: "500 !important" }}
                    >
                      Total Events
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
          <div className="col-md-6 col-lg-6 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10">
            <ChartsWidget2
              className="card-xl-stretch mb-5 mb-xl-8"
              label={eventLocationWiseLabel}
              dataList={eventLocationWiseData}
              title={"Event Location Wise"}
            />
          </div>
          <div className="col-md-6 col-lg-6 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10">
            <ChartsWidget
              className="card-xl-stretch mb-5 mb-xl-8"
              label={eventCategoryWiseLabel}
              dataList={eventCategoryWiseData}
              title={"Event Category Wise"}
            />
          </div>

          
        </KTCard>
      </>
    </Card>
  );
};

export default Dashboard;
