import { TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useMutation } from "react-query";
import { KTCard } from "../../_metronic/helpers";
import { getYoutubeUrl, updateYoutubeUrl } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
// import AddEditUser from "./AddEditUser";

const AppConfig: React.FC = () => {
  const [youtubeUrl, setYoutubeUrl] = useState("");

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setYoutubeUrl(event.target.value);
  };

  const {
    mutateAsync: getYoutubeUrlData,
    data: YoutubeUrlData,
    isLoading: isGetYoutubeUrl,
  } = useMutation(getYoutubeUrl);

  const { mutateAsync: updateUrl, isLoading: isUpdatingUrl } = useMutation(
    "update-url",
    updateYoutubeUrl,
  );

  const handleSubmit = useCallback(async () => {
    let bodyParams = {
      id: 1,
      key: "Youtube_URL",
      value: youtubeUrl,
      description: null,
    };
    let response = await updateUrl(bodyParams);
    if (response?.result) {
      snackActions.success("Youtube URL Updated Successfully!");
    } else {
      snackActions.error(response?.message ?? "Something went wrong!");
    }
  }, [updateUrl, youtubeUrl]);

  useEffect(() => {
    getYoutubeUrlData({});
  }, [getYoutubeUrlData]);

  useEffect(() => {
    YoutubeUrlData && setYoutubeUrl(YoutubeUrlData?.result?.value);
  }, [YoutubeUrlData]);

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <h3 style={{ position: "absolute", top: "5px" }}>
            Add / Update Youtube URL :
          </h3>

          <div style={{ marginTop: "50px", display: "flex", gap: "10px" }}>
            <TextField
              label="Enter YouTube URL"
              variant="outlined"
              fullWidth
              value={youtubeUrl}
              onChange={handleUrlChange}
            />
          </div>

          <div className="d-flex justify-content-between">
            <Button
              disabled={isUpdatingUrl}
              variant="success"
              style={{
                width: "100px",
                marginLeft: "auto",
                marginBottom: "10px",
                marginTop: "25px",
              }}
              onClick={handleSubmit}
            >
              {isUpdatingUrl ? "Loading.." : "Submit"}
            </Button>
          </div>
        </KTCard>
      </>
    </Card>
  );
};

export default AppConfig;
