let userInfo = localStorage.getItem("userInfo");

export function useUserInfo() {
  if (userInfo) {
    return JSON.parse(userInfo);
  } else {
    return null;
  }
}

export const isSuperAdmin = userInfo
  ? JSON.parse(userInfo)?.roleName === "SuperAdmin"
    ? true
    : false
  : false;

export const isAdmin = userInfo
  ? JSON.parse(userInfo)?.roleName === "Admin"
    ? true
    : false
  : false;
