/* eslint-disable react/jsx-no-target-blank */
import { isSuperAdmin } from "../../../../../hooks/useUserInfo";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";

const SidebarMenuMain = () => {
  interface ISideBarChildMenu {
    path: string;
    title: string;
    child: [];
    hasBullet: boolean;
  }
  interface ISideBarMenu {
    path: string;
    title: string;
    icon: string;
    fontIcon: string;
    child: ISideBarChildMenu[] | [];
    hasBullet: boolean;
    isSuperAdmin: boolean;
    isAdmin: boolean;
  }
  const sideBarMenus: ISideBarMenu[] = [
    {
      path: "/dashboard",
      icon: "abstract-3",
      title: "Dashboard",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/user",
      icon: "user-edit",
      title: "Users Information",
      fontIcon: "bi-layers",
      child: [
        {
          path: "/user/list",
          title: "All Users",
          child: [],
          hasBullet: true,
        },
      ],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/all-events",
      icon: "abstract-32",
      title: "Events",
      fontIcon: "bi-layers",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/event",
      icon: "abstract-13",
      title: "Events Configuration",
      fontIcon: "bi-layers",
      child: [
        {
          path: "/event/category",
          title: "Categories",
          child: [],
          hasBullet: true,
        },
        {
          path: "/event/info-list",
          title: "Info Tabs",
          child: [],
          hasBullet: true,
        },
        // {
        //   path: "/event/infotab",
        //   title: "Info Tabs",
        //   child: [],
        //   hasBullet: true,
        // },
        // {
        //   path: "/events/location",
        //   title: "Event Location",
        //   child: [],
        //   hasBullet: true,
        // },
      ],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },

    // {
    //   path: "/admin-user",
    //   icon: "security-user",
    //   title: "Admin User",
    //   fontIcon: "bi-layers",
    //   child: [],
    //   hasBullet: false,
    //   isSuperAdmin,
    //   isAdmin: false,
    // },
    {
      path: "/terms-conditions",
      icon: "file",
      title: "Terms & Conditions",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/privacy-policy",
      icon: "lock-2",
      title: "Privacy Policy",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/about-app",
      icon: "information-2",
      title: "About App",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/blog",
      icon: "abstract-32",
      title: "Blog",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },
    {
      path: "/app-config",
      icon: "setting",
      title: "App-Config",
      fontIcon: "bi-app-indicator",
      child: [],
      hasBullet: false,
      isSuperAdmin,
      isAdmin: true,
    },

    // {
    //   path: "/google-advert",
    //   icon: "information-2",
    //   title: "Google Advertisement",
    //   fontIcon: "bi-app-indicator",
    //   child: [],
    //   hasBullet: false,
    //   isSuperAdmin,
    //   isAdmin: false,
    // },
    // {
    //   path: "/help",
    //   icon: "question-2",
    //   title: "Help",
    //   fontIcon: "bi-app-indicator",
    //   child: [],
    //   hasBullet: false,
    //   isSuperAdmin,
    //   isAdmin: false,
    // },
  ];

  return (
    <>
      {/* <SidebarMenuItem
        to="/dashboard"
        icon="element-11"
        title={"Dashboard"}
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItem
        to="/event"
        icon="element-11"
        title={"Event"}
        fontIcon="bi-app-indicator"
      /> */}

      {/* <SidebarMenuItemWithSub
        to="/app-user"
        icon="element-11"
        title={"Users"}
        fontIcon="bi-app-indicator"
      /> */}

      {/* <SidebarMenuItemWithSub
        to="/user"
        title="User"
        fontIcon="bi-chat-left"
        icon="message-text-2"
      >
        <SidebarMenuItem
          to="/user/list"
          title="List of Users"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/user/banned-user"
          title="Banned User List"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub> */}
      {/*
      <SidebarMenuItem
        to="/terms-conditions"
        icon="switch"
        title="Terms Conditions"
        fontIcon="bi-layers"
      />

      <SidebarMenuItem
        to="/privacy-policy"
        icon="switch"
        title="privacy policy"
        fontIcon="bi-layers"
      />

      <SidebarMenuItem
        to="/about-app"
        icon="switch"
        title="about-app"
        fontIcon="bi-layers"
      /> */}

      {sideBarMenus.map((menu: ISideBarMenu) => {
        return menu.isSuperAdmin || menu.isAdmin ? (
          <div key={menu.title}>
            {menu.child.length ? (
              <SidebarMenuItemWithSub
                to={menu.path}
                title={menu.title}
                hasBullet={menu.hasBullet}
                fontIcon={menu.fontIcon}
                icon={menu.icon}
              >
                {menu.child.map((menuChild: ISideBarChildMenu) => (
                  <SidebarMenuItem
                    key={menu.title}
                    to={menuChild.path}
                    title={menuChild.title}
                    hasBullet={menuChild.hasBullet}
                  />
                ))}
              </SidebarMenuItemWithSub>
            ) : (
              <SidebarMenuItem
                to={menu.path}
                title={menu.title}
                hasBullet={menu.hasBullet}
                fontIcon={menu.fontIcon}
                icon={menu.icon}
              />
            )}
          </div>
        ) : null;
      })}
    </>
  );
};

export { SidebarMenuMain };
